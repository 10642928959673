import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css"
import {Provider} from "react-redux";
import store from "./store/store";
import * as amplitude from '@amplitude/analytics-browser';
import {ExternalPlatformController} from "./utility/ExternalPlatformController/ExternalPlatformController";
import VKConnect from "./utility/VKConnect";

// @ts-ignore
export const externalPlatformController = new ExternalPlatformController(process.env.REACT_APP_PLATFORM)
VKConnect.send("VKWebAppInit");

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLDivElement);
amplitude.init('330631688a2f0761aec7664a8e0cd69c');

// @ts-ignore
console.log(`Мир Букв v1.2.1 ${process.env.REACT_APP_PLATFORM}`)

root.render(<Provider store={store}>
  <App/>
</Provider>)
// @ts-ignore
// if (process.env.NODE_ENV === "development") {
//   import("./eruda").then(({default: eruda}) => {
//   }); //runtime download
// }