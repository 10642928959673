import {
  ClaimRewardPayload, claimRewards, selectCurrentDiamonds,
  selectCurrentLocalLevel,
  selectCurrentTips,
  selectCurrentUserData, updateLocalLevel
} from "../../../../store/Slices/userSlice";
import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../store/store";
import {
  completeTipTrainingProgress,
  selectCurrentTipTrainingProgress
} from "../../../../store/Slices/connectionHelperSlice";
import {setGameIsOver, setHammerActivationStatus} from "../../../../store/Slices/crosswordSlice";
import {useAdvertisingMutation} from "../../../../store/APIs/crosswordApi";
import {setCurrentPopout, setCurrentWarningPopout} from "../../../../store/Slices/popoutsSlice";
import {setRoute} from "../../../../store/Slices/routerSlice";
import sha256 from "../../../../utility/sha256";
import VKWebApp from "../../../../utility/VKWebApp";
import * as amplitude from "@amplitude/analytics-browser";
import {Button, ProgressBar} from "../../../UI";
import PNGs, {PreloadImages} from "../../../../assets/images";
import SVGs from "../../../../assets/svg";
import {motion} from "framer-motion";
import {externalPlatformController} from "../../../../index";

export interface ILevelChangerRewardController {
  serverLevel: number | undefined
  adsReward: ClaimRewardPayload
}

export default function LevelChangerRewardController({
                                                       adsReward,
                                                       serverLevel,
                                                       ...props
                                                     }: ILevelChangerRewardController & React.HTMLProps<HTMLDivElement>) {
  const dispatch = useAppDispatch()
  const [adIsClicked, setAdIsClicked] = useState<boolean>(false);
  const localLevel = useAppSelector(selectCurrentLocalLevel)
  const tipTrainingProgress = useAppSelector(selectCurrentTipTrainingProgress)
  const userData = useAppSelector(selectCurrentUserData)
  const tips = useAppSelector(selectCurrentTips)
  const diamonds = useAppSelector(selectCurrentDiamonds)
  const [advAvailable, setAdvAvailable] = useState<boolean>(true);

  const handleMissHammer = () => {
    dispatch(setHammerActivationStatus(false))
    if (
      tipTrainingProgress?.hammer.isUsed ||
      (typeof tipTrainingProgress?.hammer.isUsed === "undefined")
    ) return;
    dispatch(completeTipTrainingProgress({type: "hammer"}))
  }

  const [useAdvertising, {data}] = useAdvertisingMutation()

  const onNextLevelButtonClick = () => {
    if (!localLevel || !serverLevel) return;
    dispatch(updateLocalLevel(localLevel + 1))
    dispatch(setCurrentPopout({
      name: "hidden"
    }))
    dispatch(setRoute({
      view: "default",
      panel: "levels"
    }))
    handleMissHammer()
    dispatch(setGameIsOver(false))
    if (serverLevel % 3 === 0) {
      externalPlatformController.showAd("interstitial")
    }
    setAdvAvailable(true)
  }


  const onAdsButtonClick = async () => {
    if (!localLevel || !tips || typeof diamonds === "undefined") return;

    setAdIsClicked(true)
    setAdvAvailable(false)

    const type = localLevel % 8 === 0 ? "level_8" : "level"
    const ad_id = await sha256(`${tips.lamp}${tips.hammer}${diamonds}${type}`)

    const showNoAdsWarning = () => {
      dispatch(setCurrentWarningPopout({
        heading: "Реклама закончилась :(",
        article: "К сожалению, у нас сейчас нет рекламы для показа, попробуйте позже!",
      }))
    }

    const isAvailable = await externalPlatformController.checkAdAvailable("reward");
    if (isAvailable) {
      externalPlatformController.showAd("reward").then(data => {
        if (data.result) {
          if (!localLevel || !userData) return;
          handleMissHammer()

          dispatch(claimRewards(adsReward))
          dispatch(updateLocalLevel(localLevel + 1))
          dispatch(setCurrentPopout({
            name: "hidden"
          }))
          dispatch(setGameIsOver(false))
          // @ts-ignore
          const externalPlatform = process.env.REACT_APP_PLATFORM;
          useAdvertising({
            type: type,
            platform: externalPlatform,
            ad_id: ad_id,
            user_id: String(userData.user_id)
          }) // Здесь

          const query = {
            place: "window_win",
            type: "interstitial"
          }

          amplitude.track("WatchedAd", query)
        } else {
          showNoAdsWarning()
        }

        onNextLevelButtonClick()
        setAdIsClicked(false)
      }).catch(error => {
        setAdIsClicked(false)
        showNoAdsWarning()
      })
    }
  }

  let bonusTextSize;
  let nextTextSize;
  let progressSpaceY;

  if (innerHeight < 500) {
    bonusTextSize = "text-[18px]"
    nextTextSize = "text-[16px]"
    progressSpaceY = "space-y-4"
  } else if (innerHeight < 600) {
    bonusTextSize = "text-[19px]"
    nextTextSize = "text-[17px]"
    progressSpaceY = "space-y-5"
  } else if (innerHeight < 700) {
    bonusTextSize = "text-[20px]"
    nextTextSize = "text-[18px]"
    progressSpaceY = "space-y-6"
  } else {
    progressSpaceY = "space-y-8"
    bonusTextSize = "text-[24px]"
    nextTextSize = "text-[22px]"
  }

  if (!localLevel) return null;

  const progress = localLevel % 8
  const progressCalculated = progress === 0 ? 8 : progress
  const progressPercentage = (progressCalculated / 8) * 100

  return (
    <div className={`flex grow mt-4 flex-col items-center justify-end px-2 box-border ${props.className}`}>
      <div className={`flex flex-col justify-between ${progressSpaceY} items-center`}>
        <ProgressBar currentLevel={localLevel} image={
          <img
            style={{
              width: "min(20vh, 80px)",
              right: "max(-5vh, -40px)",
              top: "min(4vh, 20px)",
            }}
            src={PNGs.NextLocationIcon} alt="next location icon"
            className={`absolute -right-10 -translate-y-1/2 top-2.5`}/>
        }/>
        <ProgressBar currentLevel={localLevel} image={
          <img
            style={{
              width: progressCalculated === 8 ? "min(17vh, 100px)" : "min(15vh, 55px)",
              right: "-20px",
              top: "min(2vh, 10px)",
            }}
            src={progressCalculated === 8 ? PNGs.UnwrappedGift : SVGs.WrappedGift} alt="wrapped gift"
            className={`${progress === 8 ? "w-32" : "w-20"} absolute -right-10 -translate-y-1/2 top-2.5`}/>
        }/>
      </div>
      {
        advAvailable ?
          <Button
            disabled={adIsClicked}
            className={`mt-[2vh] py-3 px-4 text-start ${bonusTextSize}`}
            iconSpace={4}
            iconSize={24}
            type={"ads"}
            onClick={onAdsButtonClick}
          >
            Открыть бонусы
          </Button>
          : null
      }
      <Button
        disabled={adIsClicked}
        className={`mt-3 py-1 px-10 relative ${nextTextSize}`}
        type={"primary"}
        onClick={onNextLevelButtonClick}
      >
        Дальше
        {
          Number(serverLevel) <= 3 &&
          <motion.div
            className={`absolute -bottom-12 -right-8`}
            animate={{
              scale: [1, 1.1, 1],
              transition: {
                duration: 1,
                repeat: Infinity,
                ease: "easeInOut"
              }
            }}
          >
            <img src={PreloadImages.Finger} className={`w-[64px]`} alt=""/>
          </motion.div>
        }
      </Button>
    </div>
  )
}